import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import dogIllustration from "../../images/dog-illustration.svg";

function MalwareAnalysis() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Malware Analysis"
      />

      <section className="flex flex-col items-center md:flex-row">
        <div className="md:w-2/3 md:mr-8">
          <h1 className="p-4 mb-4 border-b-4 border-teal-700 text-bold text-3xl">
            Malware Analysis
          </h1>
          <blockquote className="pl-4 font-serif leading-loose text-justify border-l-4 border-teal-700">
            Malware is any piece of software that’s harmful to your system —
            worms, viruses, trojans, spyware, ransomware. Analyzing and removing
            malware can be a tricky business. Hack Guard exprience in Malware
            Analysis and Reverse Engineering could assist you in identifying,
            isolating and analyzing the capabilities of malicious files. we
            study its origin, process, evasion techniques, and assess its impact
            to facilitate timely detection and prevention. Whether it's a simple
            undirected attck or a complex trageted one, Malware Analysis offers
            forensic benefit that can provide you with evidence against
            external/internal perpetrators and helps you in updating your threat
            model.
          </blockquote>
        </div>
      </section>
    </Layout>
  );
}

export default MalwareAnalysis;
